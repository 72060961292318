import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectPamasko = ({children}) => {
  const token = localStorage.getItem("chosen_child");
  if(token){
    return <Navigate replace to={`/${token}`}/>
  }
  return children;
}

export default ProtectPamasko