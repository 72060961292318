import React from 'react';
import { Navigate } from 'react-router-dom';

const Protected = ({children}) => {
  const token = localStorage.getItem("guest_id");
  if(!token){
    return <Navigate replace to={"/pamasko"}/>
  }
  return children;
}

export default Protected