import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css"
import axios from 'axios';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { EcommerceProvider } from "./Common/EcommerceContext/EcommerceContext";
import { PamaskoProvider } from "./Common/PamaskoContext/PamaskoContext";

axios.defaults.baseURL = "https://backend.creditguider.pro/api"
// axios.defaults.baseURL = "http://127.0.0.1:8000/api"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SSRProvider>
    <EcommerceProvider>
    <PamaskoProvider>
      <App />
    </PamaskoProvider>
    </EcommerceProvider>
    </SSRProvider>
  </React.StrictMode>
);
reportWebVitals();
